<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0 pt-5">
			<div class="col-6">
				<h3 class="card-title align-items-start flex-column">Available Streams - {{ streams.length }} Feeds</h3>
				<span class="subhead">Streaming URL: </span>
				<span class="clickable subhead" @click="copyToClipboard('rtmp://streaming.itcglobal.com/RemoteVideo/')" v-b-tooltip="'Click to copy'"
					>rtmp://streaming.itcglobal.com/RemoteVideo/</span
				>
			</div>
			<div>
				<b-button variant="primary" v-b-modal.createStream>Add Stream</b-button>
			</div>
		</div>
		<div class="card-body">
			<b-table hover :items="streams" :fields="fields" tbody-tr-class="rowClass">
				<template #cell(stream_name)="data">
					<span @click="showVideoModal(data.item)" class="stream-name clickable pl-2">{{ data.value }}</span>
				</template>
				<template #cell(ant_id)="data">
					<span v-b-tooltip="'Click to copy'" class="clickable pl-1" @click="copyToClipboard(data.value)">{{ data.value }}</span>
				</template>
				<template #cell(admin)="data">
					<router-link :to="{ name: 'admineditaccount', params: { id: data.item.admin_id } }">
						<span class="label label-lg label-inline label-light-info">{{ data.value }}</span>
					</router-link>
				</template>
				<template #cell(users)="data">
					<div v-if="!data.value || data.value.length == 0" class="ml-1">
						<span class="label label-lg label-inline label-light-success clickable" @click="addUser(data.item)"> None </span>
					</div>
					<div v-else>
						<template v-for="user in data.value">
							<router-link
								v-if="user.username !== data.item.admin"
								:to="{ name: 'admineditaccount', params: { id: user.account_id } }"
								:key="user.account_id"
							>
								<span class="label-light-primary label label-lg label-inline clickable mx-1">
									{{ user.username }}
								</span>
							</router-link>
						</template>
					</div>
				</template>
				<template #cell(actions)="data">
					<div class="text-right">
						<span class="btn btn-icon btn-light edit-icon btn-sm mr-2" @click="openEditStream(data.item)" v-b-tooltip="'Edit Stream'">
							<i class="flaticon-more-v6" />
						</span>
						<span class="btn btn-icon btn-light edit-icon btn-sm mr-2" @click="addUser(data.item)" v-b-tooltip="'Edit User Access'">
							<i class="flaticon-user" />
						</span>
						<span class="btn btn-icon btn-light delete-icon btn-sm" @click="deleteStream(data.item)" v-b-tooltip="'Remove Stream'">
							<i class="flaticon2-trash" />
						</span>
					</div>
				</template>
			</b-table>
		</div>

		<!-- MODALS -->

		<!-- VIEWING MODAL -->
		<b-modal v-if="viewingVid" :title="viewingVid.stream_name" id="viewingModal" size="lg" hide-footer>
			<div class="d-flex justify-content-center">
				<iframe
					width="560"
					height="315"
					:src="`https://streaming.itcglobal.com:5443/RemoteVideo/play.html?name=${viewingVid.ant_id}&token=${viewingVid.jwt}`"
					frameborder="0"
					allowfullscreen
				></iframe>
			</div>
			<hr />
			<div class="d-flex justify-content-between align-items-end">
				<div v-if="streamInfo">
					<div>
						<span>Status:</span> <span class="text-dark font-size-lg">{{ streamInfo.status }}</span>
					</div>
					<div>
						<span>Speed:</span> <span class="text-dark font-size-lg">{{ streamInfo.speed }}</span>
					</div>
					<div>
						<span>Viewer Count: </span>
						<span class="text-dark font-size-lg">{{ streamInfo.hlsViewerCount + streamInfo.webRTCViewerCount + streamInfo.rtmpViewerCount }}</span>
					</div>
				</div>
				<div v-else>
					<b-spinner variant="primary" />
				</div>
				<div>
					<b-button variant="primary" @click="$bvModal.hide('viewingModal')">Close</b-button>
				</div>
			</div>
		</b-modal>
		<!-- END VIEWING MODAL  -->

		<!-- CREATE STREAM -->
		<b-modal id="createStream" :title="streamCreated ? 'Stream Successfully Created' : 'Add New Stream'" centered hide-footer @hide="modalHide">
			<b-form @submit.prevent="createStream" v-if="!streamCreated">
				<div class="col-8 offset-2">
					<b-form-group label="Stream Name" label-for="streamName">
						<b-form-input type="text" id="streamName" v-model="newStream.name" class="text-center" />
					</b-form-group>
					<b-form-group label="Admin Account" label-for="admin">
						<!-- <b-form-input type="text" id="admin" v-model="newStream.admin" class="text-center" /> -->
						<VueTypeaheadBootstrap
							:data="accounts"
							:serializer="s => s.username"
							@hit="newStream.username = $event.username"
							class="text-center typeahead"
						/>
					</b-form-group>
					<div class="text-center">
						<b-button variant="primary" type="submit" class="w-25" :disabled="!newStream.name || !newStream.username">OK</b-button>
					</div>
				</div>
			</b-form>
			<div v-else>
				<p>Name: {{ newStream.name }}</p>
				<p>
					Stream ID: <span @click="copyToClipboard(newStream.ant_id)" v-b-tooltip="'Click to copy'" class="clickable">{{ newStream.ant_id }}</span>
				</p>
				<p>
					Stream URL:
					<span @click="copyToClipboard('rtmp://streaming.itcglobal.com/RemoteVideo/')" v-b-tooltip="'Click to copy'" class="clickable">
						rtmp://streaming.itcglobal.com/RemoteVideo/
					</span>
				</p>
			</div>
		</b-modal>
		<!-- END CREATE STREAM -->

		<!-- EDIT STREAM -->
		<b-modal id="editStream" title="Edit Stream" centered hide-footer>
			<b-form @submit.prevent="editStream">
				<b-form-group label="Stream Name" label-for="streamName">
					<b-form-input type="text" id="streamName" v-model="editing.name" class="text-center" />
				</b-form-group>
				<b-form-group label="Admin Account" label-for="admin">
					<VueTypeaheadBootstrap
						:data="accounts"
						v-model="editing.admin"
						:serializer="s => s.username"
						@hit="editing.admin_id = $event.AccountID"
						class="text-center typeahead"
					/>
				</b-form-group>
				<div class="text-center mt-3">
					<b-button variant="primary" type="submit" class="w-25">OK</b-button>
				</div>
			</b-form>
		</b-modal>
		<!-- END EDIT STREAM -->

		<!-- UPDATE USERS -->
		<b-modal id="addUser" title="Edit Users" size="lg" centered hide-footer @hide="hideUserModal">
			<h4>Users with Access</h4>
			<div v-if="addingUser.users && addingUser.users.length == 0">
				<span class="label label-lg label-inline label-light-success"> No Users</span>
			</div>
			<div v-else>
				<span
					v-for="(user, index) in addingUser.users"
					class="label label-lg label-inline label-light-primary mr-2 user-label"
					@click="removeUser(user, index)"
					:key="user.username"
					>{{ user.username }}</span
				>
			</div>
			<hr />
			<div class="d-flex justify-content-between align-items-center mt-7">
				<h4>Find Users</h4>
				<div class="col-6 pl-0">
					<b-form-input type="text" id="searchAccount" v-model="searchAccount" debounce="300" placeholder="Search" />
				</div>
			</div>
			<b-table
				:items="filteredAccounts"
				:fields="['username', 'name', 'email']"
				sort-by="username"
				per-page="15"
				:current-page="currentAccountsPage"
				tbody-tr-class="accountRowClass"
				@row-clicked="accountSelected"
			></b-table>
			<div class="d-flex justify-content-end" v-if="filteredAccounts.length > 15">
				<b-pagination class="pagination" v-model="currentAccountsPage" :total-rows="filteredAccounts.length" per-page="15" />
			</div>
			<div class="text-center">
				<b-button class="w-25" variant="primary" @click="saveUsers">Save</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
	name: 'AdminVideo',
	components: {
		VueTypeaheadBootstrap: () => import('vue-typeahead-bootstrap'),
	},
	data() {
		return {
			loaded: false,
			streams: [],
			fields: [
				{ key: 'stream_name', label: 'Stream Name', sortable: true },
				{ key: 'ant_id', label: 'Stream ID' },
				{ key: 'admin', label: 'Admin', sortable: true },
				{ key: 'users', label: 'Users with Access' },
				{ key: 'creator', label: 'Created By', sortable: true },
				{ key: 'created_on', label: 'Created On', sortable: true },
				{ key: 'actions', label: '' },
			],
			viewingVid: null,
			streamInfo: null,
			streamCreated: false,
			accounts: null,
			newStream: {
				id: null,
				name: null,
				admin_id: null,
			},
			editingStream: null,
			editing: {
				name: null,
				admin: null,
				admin_id: null,
			},
			addingUser: {},
			currentAccountsPage: 1,
			searchAccount: '',
			userAccounts: [],
		};
	},
	computed: {
		...mapGetters(['currentUser']),
		filteredAccounts() {
			if (!this.searchAccount) {
				return this.userAccounts;
			} else {
				return this.userAccounts.filter(account => {
					let search = this.searchAccount.toLowerCase();
					return (
						account.name.toLowerCase().includes(search) ||
						account.username.toLowerCase().includes(search) ||
						account.email.toLowerCase().includes(search)
					);
				});
			}
		},
	},
	methods: {
		showVideoModal(item) {
			this.viewingVid = item;
			this.$bvModal.show('viewingModal');
			this.$http.get(`stream/${item.ant_id}/live`).then(resp => {
				this.streamInfo = resp.data;
			});
		},
		copyToClipboard(id) {
			navigator.clipboard.writeText(id);
		},
		createStream() {
			if (this.newStream.name && this.newStream.username) {
				let payload = {
					username: this.newStream.username,
					stream: {
						name: this.newStream.name,
					},
				};
				this.$http.post('streams', payload).then(resp => {
					if (resp.status == 200) {
						this.newStream.ant_id = resp.data.streamId;
						this.streamCreated = true;
						this.streams.push({
							id: resp.data.itc_id,
							ant_id: resp.data.streamId,
							admin_id: resp.data.admin_id,
							stream_name: resp.data.name,
							users: [],
						});
					}
				});
			}
		},
		modalHide() {
			this.streamCreated = false;
			this.newStream = {
				id: null,
				name: null,
				username: null,
			};
		},
		openEditStream(stream) {
			this.editingStream = stream;
			this.editing.name = stream.stream_name;
			this.editing.admin = stream.admin;
			this.$bvModal.show('editStream');
		},
		editStream() {
			let payload = {
				username: this.editing.username,
				stream: {
					name: this.editing.name,
					ant_id: this.editingStream.ant_id,
					admin_id: this.editing.admin_id,
				},
			};
			this.$http.put(`stream/${this.editingStream.id}`, payload).then(resp => {
				if ((resp.data.success = true)) {
					this.editingStream.stream_name = this.editing.name;
					this.editingStream.admin = this.editing.admin;
					this.$bvModal.hide('editStream');
					this.editingStream = null;
					this.streamName = null;
				}
			});
		},
		deleteStream(stream) {
			this.$bvModal
				.msgBoxConfirm(`Delete stream "${stream.stream_name}?"`, {
					title: 'Confirm Delete',
					okVariant: 'danger',
					okTitle: 'YES',
					cancelTitle: 'NO',
					footerClass: 'p-2',
				})
				.then(val => {
					if (val) {
						this.$http.delete(`stream/${stream.id}`).then(resp => {
							if (resp.data.status == 204) {
								this.streams = this.streams.filter(s => s.id !== stream.id);
							}
						});
					}
				});
		},
		addUser(stream) {
			this.addingUser = {
				id: stream.id,
				users: [],
			};
			let userids = stream.users.map(user => user.account_id);
			this.$bvModal.show('addUser');
			this.userAccounts = this.accounts.filter(account => {
				if (userids.includes(account.AccountID.toString())) {
					this.addingUser.users.push(account);
					return false;
				}
				return true;
			});
		},
		removeUser(user, index) {
			this.userAccounts.push(user);
			this.addingUser.users.splice(index, 1);
		},
		accountSelected(row, index) {
			this.addingUser.users.push(row);
			this.userAccounts = this.userAccounts.filter(account => account.AccountID !== row.AccountID);
		},
		saveUsers() {
			let ids = this.addingUser.users.map(user => user.AccountID.toString());
			this.$http.post(`streams/${this.addingUser.id}/users`, { username: this.username, users: ids }).then(resp => {
				this.hideUserModal();
				this.$bvModal.hide('addUser');
				this.load();
			});
		},
		hideUserModal() {
			this.addingUser = {};
			this.searchAccount = '';
		},
		load() {
			this.loaded = false;
			this.$http.get('admin/streams').then(resp => {
				this.streams = resp.data;
				this.loaded = true;
				this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Admin Video' }]);
			});
		},
	},
	created() {
		if (!['staff', 'noc', 'admin'].includes(this.currentUser.role)) {
			this.$router.replace({ name: 'dash' });
		}
		this.load();
		this.$http.get('accounts').then(resp => {
			this.accounts = resp.data.data;
		});
	},
};
</script>

<style lang="scss" scoped>
.subhead {
	color: #7e8299;
}
.stream-name {
	color: $primary;
}
.edit-icon i {
	color: $primary;
	cursor: pointer;
}
.delete-icon i {
	color: $danger;
	cursor: pointer;
}
.clickable:hover {
	cursor: pointer;
	color: $primary-hover;
}
::v-deep .rowClass td {
	padding: 5px 5px;
	vertical-align: middle;
}
::v-deep .typeahead input {
	text-align: center;
}
::v-deep .accountRowClass:hover {
	cursor: pointer;
	color: $primary-hover;
}
.user-label:hover {
	color: $danger;
	background-color: $danger-light;
	cursor: pointer;
}
</style>
